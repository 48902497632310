import React from "react"
import styles from "./SellerBrandHeader.module.css"
import Icon from "../../components/Icon"
import UpdateImageModal from "../../components/UpdateImageModal";

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {compressImageFile} from "../../utils/fileUtils"
import {HEADER_SIZE_BIG, HEADER_SIZE_MEDIUM} from "../../constants/interface"
import {logAnalyticsEvent} from "../../config/firebase"

class SellerBrandHeader extends React.PureComponent{
    
    constructor(props){
        super(props)
        const {sellers, sellerId} = props
        const seller = sellers.sellersById[sellerId]
        this.state = {
            localImageUrl: seller.headerImageUrl,
            locaImageFile: null,
            imageFile: null,
            modalOpen: false,
            headerSize: this.getHeaderSize()
        }
    }

    getHeaderSize = () => window.innerWidth > 1300 ? HEADER_SIZE_BIG : HEADER_SIZE_MEDIUM 
    setHeaderSize = () => {
        const headerSize = this.getHeaderSize()
        if (headerSize === this.state.headerSize) return
        this.setState({headerSize})
    }

    componentDidMount = () => {
        window.addEventListener("resize", this.setHeaderSize)
    }
    componentWillUnmount = () => {
        window.removeEventListener("resize", this.setHeaderSize)
    }

    componentDidUpdate(prevProps){
        const {sellerId, sellers} = this.props
        if (sellerId !== prevProps.sellerId){
            //if the seller has changed
            const seller = sellers.sellersById[sellerId]
            this.setState({
                localImageUrl: seller.headerImageUrl,
                localImageFile: null,
                imageFile: null,
                modalOpen: false
            })
        }
    }

    handleOpenModal = () => this.setState({modalOpen: true})
    handleCloseModal = () => this.setState({modalOpen: false})

    handleSelectImage = async (localImageUrl, localImageFile) => {
        this.setState({
            localImageUrl,
            localImageFile,
            imageFile: localImageFile
        })
    }

    handleCropImage = async (imageFile) => {
        this.setState({
            imageFile,
        })
    }

    handleSaveImage = async () => {
        const {actions, sellerId} = this.props
        const {imageFile} = this.state
        actions.toggleLoading(true)
        //compress the file
        const imageFileMed = await compressImageFile(imageFile, 1070)
        const imageFileSmall = await compressImageFile(imageFile, 400)
        await actions.fetchSetSellerHeader(sellerId, imageFile, imageFileMed, imageFileSmall)
        actions.toggleLoading(false)
        this.handleCloseModal()
        logAnalyticsEvent("seller_update_brand_header", {sellerId})
        actions.fetchLogDeviceSessionAction({
            action: "sellerUpdateBrandHeader",
            sellerId
        })
    }

    handleDeleteImage = async () => {
        const {actions, sellerId} = this.props
        if (!window.confirm("Are you sure you want to delete your header?")) return
        actions.toggleLoading(true)
        await actions.fetchDeleteSellerHeader(sellerId)
        actions.toggleLoading(false)
        this.setState({localImageUrl: "", localImageFile:null, imageFile: null})
    }

    render(){
        const {sellerId, sellers, user} = this.props
        const {modalOpen, localImageFile, localImageUrl, headerSize} = this.state
        const seller = sellers.sellersById[sellerId]
        const imageContainerStyle = seller.headerImageUrl ? styles.imageContainer : ""
        return   (
            <React.Fragment>
                <div className={`${styles.container} ${imageContainerStyle} ${seller.headerImageUrl ? styles.hasImage : ""}`}>
                    {
                        seller.headerImageUrl ?
                        <img 
                            src={
                                headerSize === HEADER_SIZE_BIG ?
                                seller.headerImageUrl
                                :
                                seller.headerImageUrlMed} 
                            alt="Seller Header Banner"/>
                        :
                        null
                    }
                    {
                        seller.roles[user.id] ?
                        <button 
                            className={`button white icon ${styles.editButton}`}
                            onClick={this.handleOpenModal}
                        >
                            <Icon icon="photo-camera"/> <span>Upload Store Banner<span className={styles.editAdvice}>1600px x 400px recommended</span></span>
                        </button>
                        :
                        null
                    }
                </div>
                <UpdateImageModal 
                    title="update header"
                    imageUrl={seller.headerImageUrl}
                    onDelete={this.handleDeleteImage} 
                    onSave={this.handleSaveImage}
                    onSelect={this.handleSelectImage} 
                    onCrop={this.handleCropImage}
                    onCloseModal={this.handleCloseModal} 
                    modalOpen={modalOpen}
                    localImageUrl={localImageUrl}
                    imageFile={localImageFile}
                    compressImage={false}
                    cropWidth={1600}
                    cropHeight={400}
                    cropShape="rect"
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    sellers: state.sellers,
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SellerBrandHeader)